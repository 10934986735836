import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ITutorial } from '../../shared/data/academy-tutorials';
import { BrowserHelper } from '../../shared/helpers/browser.helper';
import { ProgressService } from '../../shared/services/progress.service';
import { TutorialsService } from '../../shared/services/tutorials.service';
import { UserlaneService } from '../../shared/services/userlane.service';
import { environment } from '../../../environments/environment';

@Component({
    selector: 'app-lite',
    templateUrl: './lite.component.html',
    styleUrls: ['./lite.component.css'],
})
export class LiteComponent implements OnInit {
    public tutorials: ITutorial[] = [];
    public currentProgress = 0;

    public constructor(
        public tutorialsService: TutorialsService,
        public userlaneService: UserlaneService,
        private progressService: ProgressService,
        private router: Router
    ) {}

    public ngOnInit(): void {
        // PE-6889: deactivated until academy is fixed
        window.location.href = environment.dashboardUrl;
        return;

        BrowserHelper.stopEscapeKey();
        this.tutorials = this.tutorialsService.tutorials;
        this.tutorialsService.updateTutorialsProgressFromLocalStorage();
        this.currentProgress = this.tutorialsService.getCompletionPercentage();
    }

    public startTutorial(tutorial: ITutorial): void {
        if (tutorial.isBlocked !== false) {
            return;
        }

        this.tutorialsService.selectedTutorial = tutorial;
        this.router.navigate([`/lite/${tutorial.target}`], {
            replaceUrl: true,
            queryParamsHandling: 'merge',
        });
    }
}
