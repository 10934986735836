import { Component } from '@angular/core';
import { EnvironmentHelper } from '../../shared/helpers/environment.helper';

@Component({
    selector: 'app-completed',
    templateUrl: './completed.component.html',
    styleUrls: ['./completed.component.scss'],
})
export class CompletedComponent {
    public getDashboardUrl(): string {
        return EnvironmentHelper.getDashboadSrc();
    }
}
