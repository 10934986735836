import { Component, Input } from '@angular/core';

@Component({
    selector: 'playground',
    templateUrl: './playground.component.html',
    styleUrls: ['./playground.component.css'],
})
export class PlaygroundComponent {
    @Input() public currentMenu = 0;
    @Input() public contentSelection = 0;
}
