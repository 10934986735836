import { Component, Input } from '@angular/core';

@Component({
    selector: 'usln-item',
    templateUrl: './usln-item.component.html',
    styleUrls: ['./usln-item.component.css'],
})
export class UslnItemComponent {
    @Input() public id = 0;
    @Input() public title = '';
    @Input() public isCompleted = false;
    @Input() public duration = 0;
    @Input() public target = '/';
    @Input() public isBlocked = true;
}
