import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { KeycloakEventType, KeycloakService } from 'keycloak-angular';
import { from, Observable } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { EnvironmentHelper } from '../helpers/environment.helper';

@Injectable({
    providedIn: 'root',
})
export class AuthAPIService {
    private static BASE_URL = EnvironmentHelper.getAuthSrc();
    private static REALM = 'userlane';
    private static CLIENT_ID = 'academy';
    private static REALM_URL = `/realms/${AuthAPIService.REALM}`;
    private endpoint: string;

    private _state$: Observable<KeycloakEventType>;

    public constructor(protected _http: HttpClient, private keycloakService: KeycloakService) {
        this.endpoint = AuthAPIService.BASE_URL;
        this._state$ = this.keycloakService.keycloakEvents$.pipe(
            map((event) => event.type),
            shareReplay(1)
        );
    }

    public static keycloakFactory(keycloak: KeycloakService): () => void {
        return () => {
            keycloak.init({
                config: {
                    url: AuthAPIService.BASE_URL,
                    realm: AuthAPIService.REALM,
                    clientId: AuthAPIService.CLIENT_ID,
                },
                initOptions: {
                    enableLogging: !environment.production,
                    onLoad: 'check-sso',
                    silentCheckSsoRedirectUri:
                        window.location.origin + '/assets/silent-check-sso.html',
                },
            });
        };
    }

    public get state$(): Observable<KeycloakEventType> {
        return this._state$.pipe(shareReplay(1));
    }

    public isAuthenticated(): Observable<boolean> {
        return from(this.keycloakService.isLoggedIn());
    }

    public loginUser(): Observable<void> {
        return from(
            this.keycloakService.login({
                redirectUri: window.location.origin + environment.authRedirect,
            })
        );
    }

    public logoutUser(): Observable<void> {
        return from(this.keycloakService.logout());
    }
}
