<html
    data-wf-domain="simplebackend.webflow.io"
    data-wf-page="5ade1171b790d82b84238e90"
    data-wf-site="56e690cca6ebff347a3d1791"
    class="w-mod-js wf-sourcecodepro-n4-active wf-active"
>
    <link type="text/css" id="dark-mode" rel="stylesheet" href="" />
    <style type="text/css" id="dark-mode-custom-style"></style>
    <head>
        <meta charset="utf-8" />
        <title>academy-lite-success</title>
        <meta content="academy-lite-success" property="og:title" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <script
            src="https://ajax.googleapis.com/ajax/libs/webfont/1.4.7/webfont.js"
            type="text/javascript"
        ></script>
        <link
            rel="stylesheet"
            href="http://fonts.googleapis.com/css?family=Source+Code+Pro:regular&amp;subset=latin,latin-ext"
        />
        <script type="text/javascript">
            WebFont.load({ google: { families: ['Source Code Pro:regular:latin,latin-ext'] } });
        </script>
        <!--[if lt IE 9]>
            <script
                src="https://cdnjs.cloudflare.com/ajax/libs/html5shiv/3.7.3/html5shiv.min.js"
                type="text/javascript"
            ></script
        ><![endif]-->
        <script type="text/javascript">
            !(function (o, c) {
                var n = c.documentElement,
                    t = ' w-mod-';
                (n.className += t + 'js'),
                    ('ontouchstart' in o || (o.DocumentTouch && c instanceof DocumentTouch)) &&
                        (n.className += t + 'touch');
            })(window, document);
        </script>
        <style>
            .edge {
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 10px 10px 0 0px;
                border-color: #ebebeb transparent transparent transparent;
            }

            .steps-wrapper {
                white-space: nowrap;
            }

            /* END success
     animation */

            .check {
                fill: none;
                stroke: #59d653;
                stroke-linecap: round;
                stroke-linejoin: round;
                stroke-miterlimit: 10;
                stroke-width: 4;
            }

            .check {
                stroke-dasharray: 60 200;
                animation: check 1.5s cubic-bezier(0.5, 0, 0.6, 1) forwards 0s;
                opacity: 0;
            }

            @-webkit-keyframes check {
                from {
                    stroke-dashoffset: 60;
                    opacity: 1;
                }

                to {
                    stroke-dashoffset: 293;
                    opacity: 1;
                }
            }
        </style>
    </head>
    <body class="main full-body">
        <div class="academy-wrapper w-row">
            <div class="academy-nav w-col w-col-7">
                <div class="academy-menu-wrapper lite">
                    <div class="academy-nav-content">
                        <div class="register-row-spacer">
                            <div class="div-block-49">
                                <div class="success-big">
                                    <div class="w-embed">
                                        <div class="arrow">
                                            <svg
                                                version="1.1"
                                                id="Layer_1"
                                                xmlns="http://www.w3.org/2000/svg"
                                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                                x="0px"
                                                y="0px"
                                                viewBox="0 0 60 60"
                                                style="enable-background: new 0 0 60 60"
                                                xml:space="preserve"
                                            >
                                                <style type="text/css"></style>
                                                <path
                                                    class="check"
                                                    d="M40.61,23.03L26.67,36.97L13.495,23.788c-1.146-1.147-1.359-2.936-0.504-4.314
                                        c3.894-6.28,11.169-10.243,19.283-9.348c9.258,1.021,16.694,8.542,17.622,17.81c1.232,12.295-8.683,22.607-20.849,22.042
                                        c-9.9-0.46-18.128-8.344-18.972-18.218c-0.292-3.416,0.276-6.673,1.51-9.578"
                                                ></path>
                                            </svg>
                                        </div>
                                    </div>
                                </div>

                                <h1 class="h1">Amazing,<br />now let's head to the dashboard</h1>
                                <p>
                                    You are ready to move on to the dashboard where you can
                                    configure Userlane based on your requirements. Let's go!
                                </p>
                                <div class="spacer"></div>
                                <a [href]="getDashboardUrl()" class="btn-hero huge w-button"
                                    >Great, let's go!</a
                                >
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="academy-nav w-col w-col-5">
                <div class="academy-lite-badge-wrapper success">
                    <div class="academy-nav-menu w-row">
                        <div class="col-noborder w-col w-col-6"></div>
                    </div>
                    <div class="academy-content-centered">
                        <div class="div-block-43">
                            <div class="spin-img">
                                <img
                                    src="https://uploads-ssl.webflow.com/56e690cca6ebff347a3d1791/5ade11a4b790d8f0fd23906e_rays.png"
                                    class="image-11"
                                />
                            </div>
                            <img
                                src="https://uploads-ssl.webflow.com/56e690cca6ebff347a3d1791/5ade0e59156c006c6ee3787c_camping-emblem-touched-black.png"
                                width="161.5"
                                class="image-9"
                            />
                            <div class="div-block-42 _100">
                                <img
                                    src="https://uploads-ssl.webflow.com/56e690cca6ebff347a3d1791/5ade0e94bba7816bd9e56eab_camping-emblem-touched.png"
                                    width="161.5"
                                    class="image-10 color"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </body>
</html>
