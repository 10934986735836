import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { academyTutorials, ITutorial } from '../../shared/data/academy-tutorials';
import { ProgressService } from '../../shared/services/progress.service';
import { TutorialsService } from '../../shared/services/tutorials.service';
import { UserlaneService } from '../../shared/services/userlane.service';

@Component({
    selector: 'tutorial-player',
    templateUrl: './tutorial-player.component.html',
    styleUrls: ['./tutorial-player.component.css'],
})
export class TutorialPlayerComponent implements OnInit {
    public selectedTutorial: ITutorial = null;
    public currentMenuItem = 0;
    public contentSelection = -1;
    public tutorialCompleted = false;

    public constructor(
        private router: Router,
        private zone: NgZone,
        private tutorialsService: TutorialsService,
        private progressService: ProgressService,
        private userlaneService: UserlaneService
    ) {
        this.selectedTutorial = this.tutorialsService.selectedTutorial;
    }

    public ngOnInit(): void {
        if (!this.selectedTutorial) {
            this.router.navigate(['/'], { queryParamsHandling: 'merge' });

            return;
        }

        this.loadPlaygroundContent();
        this.loadTutorials();
    }

    public loadPlaygroundContent(): void {
        this.contentSelection = this.selectedTutorial.playgroundContent;
    }

    public loadTutorials(): void {
        if (this.selectedTutorial.isAssistantRequired) {
            this.userlaneService.showAssistant();
        } else if (this.selectedTutorial.isEditorRequired) {
            this.userlaneService.openEditor();
        }

        this.userlaneService.userlaneInception.loadTutorial(
            this.selectedTutorial.inceptionTutorialId,
            () => {
                setTimeout(() => {
                    if (this.selectedTutorial.userlaneTutorialId !== 0) {
                        this.userlaneService.userlane.loadTutorial(
                            this.selectedTutorial.userlaneTutorialId
                        );
                    }
                }, 500);
            }
        );

        this.waitForTutorialComplete();
        this.waitForTutorialExit();
    }

    public waitForTutorialComplete(): void {
        this.userlaneService.userlaneInception.onTutorialComplete(() => {
            this.tutorialCompleted = true;
            this.userlaneService.closeEditor();
            this.userlaneService.hideAssistant();
            this.progressService.markTutorialAsCompleted(this.selectedTutorial.inceptionTutorialId);

            const lastTutorialId: number =
                academyTutorials[academyTutorials.length - 1].inceptionTutorialId;

            if (this.selectedTutorial.inceptionTutorialId === lastTutorialId) {
                this.router.navigate(['/lite/completed'], {
                    replaceUrl: true,
                    queryParamsHandling: 'merge',
                });
            } else {
                window.location.reload();
            }
        });
    }

    public waitForTutorialExit(): void {
        this.userlaneService.userlaneInception.onTutorialExit(() => {
            this.userlaneService.closeEditor();
            this.userlaneService.hideAssistant();
            window.location.reload();
        });
    }
}
