<div class="academy-lite-badge-wrapper">
    <div class="academy-nav-menu w-row">
        <div class="col-noborder w-col w-col-6"></div>
        <div class="col-noborder w-col w-col-6">
            <a class="link-submenu uslr-item-float-right" (click)="modalGoToDashboard(true)"
                >Skip to Portal</a
            >
        </div>
    </div>
    <div class="academy-content-centered">
        <div class="div-block-45">
            <div class="div-block-44">
                <img
                    src="/assets/img/camping-emblem-touched-black.png"
                    width="161.5"
                    data-w-id="46144f1a-eb7d-1223-12b7-ffa987c5320f"
                    class="image-9"
                />
                <div class="div-block-42" [style.height.%]="progress">
                    <div
                        *ngIf="progress < 100"
                        class="text-block-22"
                        [style.opacity]="progressOpacity"
                    >
                        &nbsp; {{ progressLabel }}
                    </div>
                    <img
                        src="/assets/img/camping-emblem-touched.png"
                        width="161.5"
                        class="image-10"
                        [class.color]="progress >= 100"
                    />
                </div>
            </div>
        </div>
        <img
            *ngIf="progress < 100"
            src="/assets/img/complete-tours.png"
            width="379.5"
            srcset="/assets/img/complete-tours-p-500.png 500w, /assets/img/complete-tours.png 759w"
            sizes="280px"
            class="image-12"
        />
    </div>
</div>

<div class="modal" [hidden]="!isModalVisible">
    <div class="modal-content2">
        <div class="modal-window w-clearfix">
            <div class="title-wrapper-slim">
                <img
                    src="https://uploads-ssl.webflow.com/56e690cca6ebff347a3d1791/5ada360997c6977e081aeca9_andgone.png"
                    width="125"
                />
                <h1 class="h1">Wait a second!</h1>
                <p>
                    Stay with us for more tutorials. A better understanding of how userlanes work
                    will improve your overall experience. And who doesn't like a good experience?
                </p>
                <div class="modal-action">
                    <a
                        href="javascript:void(0)"
                        (click)="goToDashboard()"
                        class="btn-secondary red w-button"
                        >skip to Portal</a
                    >
                    <a
                        href="javascript:void(0)"
                        (click)="modalGoToDashboard(false)"
                        class="btn-hero w-button"
                        >ok, I'll continue</a
                    >
                </div>
            </div>
            <div data-balloon="Hide this tipp forever." data-balloon-pos="left" class="info-close">
                <a href="javascript:void(0)" (click)="modalGoToDashboard(false)" class="kill">§</a>
            </div>
        </div>
    </div>
</div>
