export interface IApiOption {
    api: string;
    auth: string;
    playerEditor: string;
    dashboard: string;
}

export const defaultApiOptions: { [name: string]: IApiOption } = {
    production: {
        api: 'https://api.userlane.com/',
        auth: 'https://auth.userlane.com/auth',
        playerEditor: 'https://cdn.userlane.com/userlane.js',
        dashboard: 'https://family.userlane.com',
    },
    productionUs: {
        api: 'https://api.us.userlane.com/',
        auth: 'https://auth.us.userlane.com/auth',
        playerEditor: 'https://cdn.us.userlane.com/userlane.js',
        dashboard: 'https://family.us.userlane.com',
    },
    staging: {
        api: 'https://api.usln.rocks/',
        auth: 'https://auth.usln.rocks/auth',
        playerEditor: 'https://cdn.usln.rocks/userlane.js',
        dashboard: 'https://family.usln.rocks',
    },
    localhost: {
        api: 'http://localhost:8000/',
        auth: 'http://localhost:8081/',
        playerEditor: 'https://localhost:4242/userlane.js',
        dashboard: 'https://localhost:4200/',
    },
    testing: {
        api: 'https://api-testing.usln.rocks/',
        auth: 'https://auth-testing.usln.rocks/auth',
        playerEditor: null, // no player-editor for testing env
        dashboard: 'https://family-testing.usln.rocks/',
    },
};

// Functions to get multi staging base URLs for different endpoints
export const getMultiStagingUrlForApi = (envName: string): string =>
    `https://api-${envName}.usln.rocks/`;
export const getMultiStagingUrlForAuth = (envName: string): string =>
    `https://auth-${envName}.usln.rocks/auth`;
export const getMultiStagingUrlForPlayerEditor = (envName: string): string =>
    `https://cdn.userlane.com/userlane.js`;
export const getMultiStagingUrlForDashboard = (envName: string): string =>
    `https://family-${envName}.usln.rocks`;
