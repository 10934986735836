import { Injectable } from '@angular/core';
import { academyTutorials, ITutorial } from '../data/academy-tutorials';
import { ProgressService } from './progress.service';

@Injectable({
    providedIn: 'root',
})
export class TutorialsService {
    public tutorials: ITutorial[] = academyTutorials;
    public selectedTutorial: ITutorial = null;

    public constructor(private progressService: ProgressService) {}

    public updateTutorialsProgressFromLocalStorage(): void {
        let shouldBlock = false;

        this.tutorials = this.tutorials.map((tutorial) => {
            tutorial.isCompleted = this.progressService.isTutorialCompleted(
                tutorial.inceptionTutorialId
            );
            tutorial.isBlocked = tutorial.isCompleted ? false : shouldBlock;
            shouldBlock = !tutorial.isCompleted;

            return tutorial;
        });
    }

    public getCompletionPercentage(): number {
        const countCompleted: number = this.tutorials.filter(
            (tutorial) => tutorial.isCompleted
        ).length;

        // If no tutorial has been done yet, show a progress of 10%
        return Math.floor(
            countCompleted === 0 ? 10 : (countCompleted / this.tutorials.length) * 100
        );
    }
}
