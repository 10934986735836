interface IUserConfig {
    hasSeenAcademy: boolean;
    loginCount: number;
}

export class UserModel {
    public id: string;
    public name: string;
    public email: string;
    public config: IUserConfig;
    public isEnabled: boolean;
    public isSuperAdmin: boolean;
    public authenticationCheckComplete: boolean;
    public isAuthenticated: boolean;

    public constructor(json: any = {}) {
        this.id = json.id || '';
        this.name = json.name || 'Academy User';
        this.email = json.email || '';
        this.config = json.config || { hasSeenAcademy: false, loginCount: 0 };
        this.isEnabled = json.isEnabled || false;
        this.isSuperAdmin = json.isSuperAdmin || false;
        this.authenticationCheckComplete = json.authenticationCheckComplete || false;
        this.isAuthenticated = json.isAuthenticated || false;
    }

    public toJSON() {
        return {
            id: this.id,
            name: this.name,
            email: this.email,
            config: this.config,
            isEnabled: this.isEnabled,
            isSuperAdmin: this.isSuperAdmin,
        };
    }
}
