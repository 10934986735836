import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RoutingModule } from './app-routing.module';

// Components
import { AppComponent } from './app.component';

// Services
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';
import { AuthAPIService } from './shared/services/auth.service';
import { UserlaneService } from './shared/services/userlane.service';

@NgModule({
    declarations: [AppComponent],
    imports: [BrowserModule, RoutingModule, HttpClientModule, KeycloakAngularModule],
    providers: [
        UserlaneService,
        {
            provide: APP_INITIALIZER,
            useFactory: AuthAPIService.keycloakFactory,
            multi: true,
            deps: [KeycloakService],
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
