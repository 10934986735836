import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { BrowserHelper } from './shared/helpers/browser.helper';
import { AuthAPIService } from './shared/services/auth.service';
import { UserService } from './shared/services/user.service';
import { UserlaneService } from './shared/services/userlane.service';

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>',
    encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
    public constructor(
        private router: Router,
        private userService: UserService,
        private authService: AuthAPIService,
        private userlaneService: UserlaneService
    ) {}

    public ngOnInit(): void {
        if (BrowserHelper.isMobile()) {
            this.router.navigate(['lite/mobile/not-supported'], {
                replaceUrl: true,
                queryParamsHandling: 'merge',
            });

            return;
        }
        this.userlaneService.clearUserlaneTraces();

        window.addEventListener('beforeunload', () => {
            this.userlaneService.clearUserlaneTraces();
        });

        this.userService.getAuthUser().subscribe(
            (user) => {
                if (user.isAuthenticated) {
                    this.userlaneService.loadUserlaneInstances(user, () => {
                        this.userlaneService.areUserlaneInstancesLoading = false;
                    });
                }
            },
            () => {
                this.authService.loginUser();
            }
        );
    }
}
