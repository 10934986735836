<!-- Main menu is NOT selected -->
<div class="title-wrapper-slim" [hidden]="currentMenu == 0">
    <img src="/assets/img/empty-box.png" width="125" />
    <h1 class="h1">There's nothing to do here</h1>
    <p>
        This menu item is just a dummy to have more elements to explain userlane better. We didn't
        really expect you to click on these buttons. Or did we…?
    </p>
</div>

<!-- Main menu IS selected -->
<div [hidden]="currentMenu > 0">
    <!-- No interaction mode -->
    <div *ngIf="contentSelection == 0" class="title-wrapper-slim">
        <img src="/assets/img/empty-state-disabled.jpg" width="125" />
        <h1 class="h1">No interaction required</h1>
        <p>Just lean back and learn about the editor itself!</p>
    </div>

    <div *ngIf="contentSelection == 1">
        <button type="button" id="button01" class="btn btn-info btn-friendly">
            FRIENDLY BUTTON
        </button>
        <br />
        <br />
        <button type="button" id="button02" class="btn btn-success btn-friendly">
            FRIENDLY BUTTON
        </button>
        <br />
        <br />
        <button type="button" id="button03" class="btn btn-danger btn-friendly">
            FRIENDLY BUTTON
        </button>
        <br />
        <br />
        <img src="/assets/img/up-arrow-example.png" width="122.5" class="image-4" />
    </div>
</div>
