// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

import { defaultApiOptions } from './shared';

export const environment = {
    production: false,
    apiSelectable: true,
    apiOptions: defaultApiOptions,
    authUrl: defaultApiOptions.staging.auth,
    apiUrl: defaultApiOptions.staging.api,
    dashboardUrl: defaultApiOptions.staging.dashboard,
    playerEditorUrl: defaultApiOptions.production.playerEditor,
    authRedirect: '/lite',
};
