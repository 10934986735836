import { Injectable } from '@angular/core';
import { LayerNamesEnum } from '../data/layers-constants';
import { BrowserHelper } from '../helpers/browser.helper';
import { UserModel } from '../models/user.model';
import { Userlane } from '../models/userlane.model';

@Injectable({
    providedIn: 'root',
})
export class UserlaneService {
    public readonly LOCAL_STORAGE_EDITOR_STATUS_KEY = 'userlane-editor-mode-status';
    public userlaneInception: Userlane = new Userlane(LayerNamesEnum.LAYER_ONE);
    public userlane: Userlane = new Userlane(LayerNamesEnum.LAYER_TWO);
    public areUserlaneInstancesLoading = true;

    public clearUserlaneTraces(): void {
        BrowserHelper.deleteCookiesWithNameIncludingWord('userlane');
        localStorage.removeItem(this.LOCAL_STORAGE_EDITOR_STATUS_KEY);
    }

    public loadUserlaneInstances(user: UserModel, onLoad: () => void): void {
        this.clearUserlaneTraces();

        // INCEPTION - Load the Inception (aka 3rd Layer) only when the first Userlane Injection (aka Second Layer) has finished loading
        this.userlane.loadSnippet(() => {
            // Delete all Userlane traces
            const userlaneBreadcrumpElement = document.getElementById('userlane-breadcrump');
            if (userlaneBreadcrumpElement) {
                userlaneBreadcrumpElement.parentElement.removeChild(userlaneBreadcrumpElement);
            }
            window[LayerNamesEnum.LAYER_TWO] = window[LayerNamesEnum.LAYER_ONE];
            delete window[LayerNamesEnum.LAYER_ONE];
            delete window['UserlaneCommandObject'];

            // Initialize Userlane Inception
            this.userlaneInception.loadSnippet(onLoad);
            this.userlaneInception.init(user);

            // Initialize Userlane
            this.userlane.hackEditor(user);
            this.userlane.init();
        });
    }

    public showAssistant(): void {
        const assistant: HTMLElement = document.getElementById('userlane-assistant-container');

        if (assistant) {
            assistant.style.cssText = 'display: block !important';
        }
    }

    public hideAssistant(): void {
        const assistant: HTMLElement = document.getElementById('userlane-assistant-container');

        if (assistant) {
            assistant.style.cssText = 'display: none !important';
        }
    }

    public openEditor(): void {
        this.userlane.startEditor();
    }

    public closeEditor(): void {
        BrowserHelper.clickOnElements([
            '#userlane-editor-bar-button-exit',
            '#userlane-exit-confirm-cancel',
        ]);
    }
}
