<div class="academy-training-wrapper">
    <div class="row-6 w-row">
        <div class="w-col w-col-5">
            <a
                [hidden]="tutorialCompleted"
                data-ix="academy-menu-item-back"
                routerLink="/lite"
                class="link-submenu"
                style="transition: all 0.2s ease 0s"
                replaceUrl
            >
                <span class="text-icon-left">2</span>Return home</a
            >
        </div>
        <div class="w-col w-col-2"></div>
        <div class="column-8 w-col w-col-5">
            <a
                id="goLiteLink"
                [hidden]="!tutorialCompleted"
                data-ix="academy-menu-item-back"
                routerLink="/lite"
                class="link-submenu"
                style="transition: all 0.2s ease 0s"
                replaceUrl
                >Next lesson
                <span class="text-icon-right">6</span>
            </a>
        </div>
    </div>
    <div class="academy-training-content">
        <div class="container academy">
            <div class="wrapper-flex full-height w-clearfix">
                <div class="academy-menu">
                    <a
                        class="link-submenu"
                        [ngClass]="{ active: currentMenuItem == 0 }"
                        (click)="currentMenuItem = 0"
                    >
                        <span class="text-icon-left">ö</span>Home</a
                    >
                    <a
                        class="link-submenu"
                        [ngClass]="{ active: currentMenuItem == 1 }"
                        (click)="currentMenuItem = 1"
                    >
                        <span class="text-icon-left">l</span>Menu item 1</a
                    >
                    <a
                        class="link-submenu"
                        [ngClass]="{ active: currentMenuItem == 2 }"
                        (click)="currentMenuItem = 2"
                    >
                        <span class="text-icon-left">r</span>Menu item 2</a
                    >
                    <img
                        src="/assets/img/example-menu.png"
                        srcset="
                            /assets/img/example-menu.png 500w,
                            /assets/img/example-menu.png 584w
                        "
                        sizes="(max-width: 479px) 80vw, 200px"
                        class="image-8"
                    />
                </div>
                <div class="content academy">
                    <div class="academy-content">
                        <playground
                            [contentSelection]="contentSelection"
                            [currentMenu]="currentMenuItem"
                        ></playground>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
