import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// Components
import { BadgeContainerComponent } from './components/badge-container/badge-container.component';
import { PlaygroundComponent } from './components/playground/playground.component';
import { UslnItemComponent } from './components/usln-item/usln-item.component';
import { CompletedComponent } from './pages/completed/completed.component';
import { LiteComponent } from './pages/lite/lite.component';
import { NoMobileComponent } from './pages/no-mobile/no-mobile.component';
import { TutorialPlayerComponent } from './pages/tutorial-player/tutorial-player.component';

export const routes: Routes = [
    { path: 'lite', component: LiteComponent },
    { path: 'lite/completed', component: CompletedComponent },
    { path: 'lite/:tutorial', component: TutorialPlayerComponent },
    { path: 'lite/mobile/not-supported', component: NoMobileComponent },
    { path: '**', redirectTo: 'lite' },
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forRoot(routes, {
            enableTracing: false,
            relativeLinkResolution: 'legacy',
        }),
    ],
    declarations: [
        CompletedComponent,
        LiteComponent,
        BadgeContainerComponent,
        UslnItemComponent,
        TutorialPlayerComponent,
        PlaygroundComponent,
        NoMobileComponent,
    ],
    exports: [RouterModule],
})
export class RoutingModule {}
