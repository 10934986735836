export interface ITutorial {
    id: number;
    title: string;
    target: string;
    duration: number;
    isBlocked: boolean;
    isCompleted: boolean;
    isEditorRequired: boolean;
    isAssistantRequired: boolean;
    playgroundContent: number;
    userlaneTutorialId: number;
    inceptionTutorialId: number;
}

export const academyTutorials: ITutorial[] = [
    {
        id: 1,
        title: 'General Introduction to Userlane',
        target: 'general-introduction-userlane',
        duration: 2,
        isBlocked: true,
        isCompleted: false,
        isEditorRequired: false,
        isAssistantRequired: true,
        playgroundContent: 0,
        userlaneTutorialId: 0,
        inceptionTutorialId: 17257,
    },
    {
        id: 2,
        title: 'Build your first Guide',
        target: 'build-your-first-tour',
        duration: 4,
        isBlocked: true,
        isCompleted: false,
        isEditorRequired: true,
        isAssistantRequired: false,
        playgroundContent: 1,
        userlaneTutorialId: 17272,
        inceptionTutorialId: 17259,
    },
    {
        id: 3,
        title: 'Edit the content of a user',
        target: 'edit-the-content-of-a-user',
        duration: 5,
        isBlocked: true,
        isCompleted: false,
        isEditorRequired: true,
        isAssistantRequired: false,
        playgroundContent: 1,
        userlaneTutorialId: 17243,
        inceptionTutorialId: 17604,
    },
    {
        id: 4,
        title: 'Add a link (media) to your Guide',
        target: 'add-a-link-media-to-your-userlane',
        duration: 3,
        isBlocked: true,
        isCompleted: false,
        isEditorRequired: true,
        isAssistantRequired: false,
        playgroundContent: 1,
        userlaneTutorialId: 17243,
        inceptionTutorialId: 18024,
    },
    {
        id: 5,
        title: 'Final check? Adapt your Guide',
        target: 'final-check-adapt-your-userlane',
        duration: 2,
        isBlocked: true,
        isCompleted: false,
        isEditorRequired: true,
        isAssistantRequired: false,
        playgroundContent: 1,
        userlaneTutorialId: 18025,
        inceptionTutorialId: 17950,
    },
];
