import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentHelper } from '../helpers/environment.helper';
import { UserModel } from '../models/user.model';

@Injectable({
    providedIn: 'root',
})
export class ApiService {
    public constructor(private httpClient: HttpClient) {}

    public getCurrentUser(): Promise<UserModel> {
        return new Promise<UserModel>((resolve, reject) => {
            this.httpClient
                .get(`${EnvironmentHelper.getApiSrc()}v2/users/me`, { withCredentials: true })
                .subscribe(
                    (response) => {
                        resolve(new UserModel(response));
                    },
                    (error: any) => {
                        reject(error);
                    }
                );
        });
    }

    public updateUser(user: UserModel): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.httpClient
                .put(`${EnvironmentHelper.getApiSrc()}v2/users/${user.id}`, user.toJSON(), {
                    withCredentials: true,
                })
                .subscribe(
                    () => {
                        resolve();
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }
}
