import { AfterViewInit, Component, Input } from '@angular/core';
import { EnvironmentHelper } from '../../shared/helpers/environment.helper';

@Component({
    selector: 'badge-container',
    templateUrl: './badge-container.component.html',
    styleUrls: ['./badge-container.component.css'],
})
export class BadgeContainerComponent implements AfterViewInit {
    @Input('progress') public progressTo: number;

    public progress = 0;
    public progressLabel = '';
    public progressOpacity = 0;
    public isModalVisible = false;

    public runAnimation(time: number) {
        setTimeout(() => {
            this.progress = this.progressTo;
            this.progressOpacity = 1;
            let countUp = 0;

            const countUpTimer = setInterval(() => {
                this.progressLabel = `${countUp++}%`;
            }, 800 / this.progressTo);

            setTimeout(() => {
                this.progressLabel = `${this.progressTo.toString()}%`;
                clearInterval(countUpTimer);
            }, 800);
        }, time);
    }

    public ngAfterViewInit() {
        this.runAnimation(1000);
    }

    // Separate function only for testing purposes
    public goToDashboard() {
        window.location.replace(EnvironmentHelper.getDashboadSrc());
    }

    public modalGoToDashboard(shouldOpen: boolean) {
        if (this.progress === 100 && shouldOpen) {
            this.goToDashboard();
        } else {
            this.isModalVisible = shouldOpen;
        }
    }
}
