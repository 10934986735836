export enum LayerNamesEnum {
    LAYER_ONE = 'userlaneFacade',
    LAYER_TWO = 'userlaneFacade2ndLayer',
}

export interface ILayerProperty {
    id: number;
    name: string;
}

export const layersConstants: { property: { [key: string]: ILayerProperty } } = {
    property: {
        [LayerNamesEnum.LAYER_ONE]: {
            id: 32217,
            name: 'inception',
        },
        [LayerNamesEnum.LAYER_TWO]: {
            id: 32220,
            name: 'userlane',
        },
    },
};
