import { Injectable } from '@angular/core';
import { KeycloakEventType } from 'keycloak-angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, switchMap, take } from 'rxjs/operators';
import { UserModel } from '../models/user.model';
import { ApiService } from './api.service';
import { AuthAPIService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private currentUser: BehaviorSubject<UserModel>;
    public constructor(private apiService: ApiService, private authService: AuthAPIService) {
        this.currentUser = new BehaviorSubject(new UserModel({}));
        this.checkAuthState();
    }

    public state(): Observable<UserModel> {
        return this.currentUser.asObservable();
    }

    public getCurrentUser(): UserModel {
        return this.currentUser.getValue();
    }

    public markAcademyAsSeen(): void {
        if (!this.getCurrentUser().config.hasSeenAcademy) {
            this.getCurrentUser().config.hasSeenAcademy = true;
            this.apiService.updateUser(this.getCurrentUser());
        }
    }

    public getAuthUser() {
        return this.state().pipe(
            filter((user) => user.authenticationCheckComplete),
            first()
        );
    }

    private checkAuthState(): void {
        this.authService.state$
            .pipe(
                filter((type) => type === KeycloakEventType.OnReady),
                take(1),
                switchMap(() => this.apiService.getCurrentUser())
            )
            .subscribe(
                (res) => {
                    const user: UserModel = this.getCurrentUser();
                    user.id = res.id;
                    user.name = res.name;
                    user.email = res.email;
                    user.isAuthenticated = true;
                    user.authenticationCheckComplete = true;
                    this.currentUser.next(user);

                    this.markAcademyAsSeen();
                },
                () => {
                    const user: UserModel = this.getCurrentUser();
                    user.isAuthenticated = false;
                    user.authenticationCheckComplete = true;
                    this.currentUser.next(user);
                    this.authService.loginUser();
                }
            );
    }
}
