import { ILayerProperty, LayerNamesEnum, layersConstants } from '../data/layers-constants';
import { EnvironmentHelper } from '../helpers/environment.helper';
import { UserModel } from './user.model';

export class Userlane {
    private readonly _layerName: LayerNamesEnum;
    private readonly _property: ILayerProperty;

    private _userlane: any;

    public constructor(layerName: LayerNamesEnum) {
        this._layerName = layerName;
        this._property = layersConstants.property[layerName];
    }
    public get userlane(): any {
        return this._userlane || window[this._property.name];
    }

    public loadSnippet(onLoad?: () => void): void {
        (function (i, s, o, g, r, a, m) {
            i['UserlaneCommandObject'] = r;
            i[r] =
                i[r] ||
                function (...args) {
                    (i[r].q = i[r].q || []).push(args);
                };
            a = s.createElement(o);
            m = s.getElementsByTagName(o)[0];
            a.async = 0;
            a.src = g;
            a.onload = () => {
                if (onLoad) {
                    onLoad();
                }
            };
            m.parentNode.insertBefore(a, m);
        })(window, document, 'script', EnvironmentHelper.getUserlaneSrc(), this._property.name);
    }

    public init(user?: UserModel): void {
        if (user) {
            this.userlane('identify', user.id);
        }

        this.userlane('init', this._property.id, this._property.name);
        this.userlane('config', 'allowStepsOnUserlaneElements', true);
    }

    public loadTutorial(tutorialId: number, onLoad?: () => void): void {
        window[this._layerName]
            .getModule('startBootstrap')
            .mainTutorialOperator.loadAndStartTutorial({
                tutorialID: tutorialId,
                eventData: { src: 'academy' },
                sourcePath: '',
                skipSaveProgress: false,
                callback: onLoad,
            });
    }

    public startEditor(tutorialId?: number): void {
        tutorialId ? this.userlane('startEditor', tutorialId) : this.userlane('startEditor');
    }

    public onTutorialComplete(callback: () => void): void {
        this.userlane('onComplete', callback);
    }

    public onTutorialExit(callback: () => void): void {
        this.userlane('onExit', callback);
    }

    public hackEditor(user: UserModel): void {
        const userlaneApiService = window[this._layerName]
            .getModule('factory')
            .getAPITypedService();

        userlaneApiService.initialize = (propertyId, callback) => {
            callback(true);
        };

        userlaneApiService.getUserData = (propertyId, callback, error) => {
            callback({
                hasEditorAccess: true,
                name: user.name,
                role: 'admin',
            });
        };

        // noinspection JSUnusedLocalSymbols
        userlaneApiService.getAllTutorials = (
            propertyId: any,
            filteredVisibilities: string[],
            callback: any,
            error?: any
        ): void => {
            callback({
                launchers: [],
                looseTutorials: [],
            });
        };

        userlaneApiService.getAllLanguages = () => undefined;
    }
}
