import { environment } from '../../../environments/environment';
import {
    defaultApiOptions,
    getMultiStagingUrlForApi,
    getMultiStagingUrlForAuth,
    getMultiStagingUrlForDashboard,
    IApiOption,
} from '../../../environments/shared';
import { BrowserHelper } from './browser.helper';

export class EnvironmentHelper {
    public static readonly localStorageApiUrlKey: string = 'userlane-api';
    public static readonly branchBasedPlayerUrl: string =
        'https://static.staging.userlane.com/e2e/player-editor/branches';

    public static getBranchParamValue(): string {
        return BrowserHelper.getQueryParamByName('branch');
    }

    public static getApiParamValue(): string {
        return BrowserHelper.getQueryParamByName('api');
    }

    public static getUserlaneSrc(): string {
        if (environment.apiSelectable) {
            // First, check if a param was specified
            const branchParamValue: string = EnvironmentHelper.getBranchParamValue();
            if (branchParamValue) {
                if (environment.apiOptions[branchParamValue]) {
                    return environment.apiOptions[branchParamValue].playerEditor;
                }

                return `${EnvironmentHelper.branchBasedPlayerUrl}/${branchParamValue}/userlane.js`;
            }

            // Next, check local storage (set via dasboard)
            const apiOption = EnvironmentHelper.loadEnvironmentFromLocalStorage();
            if (apiOption && apiOption.playerEditor) {
                return apiOption.playerEditor;
            }
        }

        return defaultApiOptions.production.playerEditor;
    }

    public static getApiSrc(): string {
        if (environment.apiSelectable) {
            // First, check if a param was specified
            const apiParamValue: string = EnvironmentHelper.getApiParamValue();
            if (apiParamValue) {
                if (environment.apiOptions[apiParamValue]) {
                    return environment.apiOptions[apiParamValue].api;
                } else {
                    return getMultiStagingUrlForApi(apiParamValue);
                }
            }
            // Next, check local storage (set via dasboard)
            const apiOption = EnvironmentHelper.loadEnvironmentFromLocalStorage();
            if (apiOption && apiOption.api) {
                return apiOption.api;
            }
        } else {
            const envName = EnvironmentHelper.getMultiStagingEnvNameFromHost();
            if (envName) {
                return getMultiStagingUrlForApi(envName);
            }
        }

        return environment.apiUrl;
    }

    public static getDashboadSrc(): string {
        if (environment.apiSelectable) {
            // Check local storage (set via dasboard)
            const apiOption = EnvironmentHelper.loadEnvironmentFromLocalStorage();
            console.log({ apiOption });
            if (apiOption && apiOption.dashboard) {
                return apiOption.dashboard;
            }
        } else {
            const envName = EnvironmentHelper.getMultiStagingEnvNameFromHost();
            if (envName) {
                return getMultiStagingUrlForDashboard(envName);
            }
        }

        return environment.dashboardUrl;
    }

    public static getAuthSrc(): string {
        // Auth URL must use the same env as API

        if (environment.apiSelectable) {
            // First, check if a param was specified
            const apiParamValue: string = EnvironmentHelper.getApiParamValue();
            if (apiParamValue) {
                if (environment.apiOptions[apiParamValue]) {
                    return environment.apiOptions[apiParamValue].auth;
                } else {
                    return getMultiStagingUrlForAuth(apiParamValue);
                }
            }
            // Next, check local storage (set via dasboard)
            const apiOption = EnvironmentHelper.loadEnvironmentFromLocalStorage();
            if (apiOption && apiOption.auth) {
                return apiOption.auth;
            }
        } else {
            const envName = EnvironmentHelper.getMultiStagingEnvNameFromHost();
            if (envName) {
                return getMultiStagingUrlForAuth(envName);
            }
        }

        return environment.authUrl;
    }

    public static isProductionBuild(): boolean {
        return environment.production;
    }

    /**
     * Uses the host URL to determine if dashboard is running in a multistaging env or not.
     * @return string|null the name of the environment or null
     */
    public static getMultiStagingEnvNameFromHost(): string | null {
        const host = window.location.host;
        const multistagingEnv = host.match(/academy-(.*)\.usln.rocks/);

        return multistagingEnv ? multistagingEnv[1] : null;
    }

    private static loadEnvironmentFromLocalStorage(): IApiOption {
        let apiOption: IApiOption = null;
        try {
            apiOption = JSON.parse(localStorage.getItem(EnvironmentHelper.localStorageApiUrlKey));
        } catch (e) {
            return null;
        }

        return apiOption;
    }
}
