import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class ProgressService {
    public readonly LOCAL_STORAGE_PROGRESS_KEY = 'userlane-academy-progress';

    private _completedTutorials: number[] = [];

    public constructor() {
        this.refreshProgress();
    }

    public refreshProgress(): void {
        const currentProgress: string = localStorage.getItem(this.LOCAL_STORAGE_PROGRESS_KEY);

        if (currentProgress) {
            this._completedTutorials = JSON.parse(atob(currentProgress));
        }
    }

    public updateProgress(): void {
        localStorage.setItem(
            this.LOCAL_STORAGE_PROGRESS_KEY,
            btoa(JSON.stringify(this._completedTutorials))
        );
    }

    public markTutorialAsCompleted(id: number): void {
        if (this.isTutorialCompleted(id) === false) {
            this._completedTutorials.push(id);
            this.updateProgress();
        }
    }

    public isTutorialCompleted(id: number): boolean {
        return this._completedTutorials.includes(id);
    }
}
