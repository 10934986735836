<div id="loading" *ngIf="userlaneService.areUserlaneInstancesLoading">
    <span>Loading...</span>
</div>
<div id="academy" *ngIf="!userlaneService.areUserlaneInstancesLoading" class="page-container">
    <div class="row">
        <div class="col-md-7">
            <div class="academy-nav">
                <div class="academy-menu-wrapper lite">
                    <div class="academy-nav-content">
                        <div class="register-row-spacer">
                            <div>
                                <h1 class="h1">Creating and editing Guides, let's go</h1>
                                <p>
                                    Below you'll find interactive Guides that teach you the basics
                                    about how to use Userlane. Not only will you have a great
                                    experience, you'll also see our solution in action. Let's go:
                                </p>
                                <div class="spacer"></div>
                                <usln-item
                                    *ngFor="let tutorial of tutorials; index as i"
                                    [id]="i + 1"
                                    title="0{{ i + 1 }} - {{ tutorial.title }}"
                                    routerLink=""
                                    [duration]="tutorial.duration"
                                    [isCompleted]="tutorial.isCompleted"
                                    [isBlocked]="tutorial.isBlocked"
                                    (click)="startTutorial(tutorial)"
                                    replaceUrl
                                    tabindex="-1"
                                >
                                </usln-item>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <badge-container [progress]="currentProgress" class="col-md-5"></badge-container>
    </div>
</div>
